<template>
  <div>
    <b-card class="plans-middle">
      <b-card-body>
        <b-row>
          <b-col offset-xl="2" xl="5" class="pro-time">
            <span class="pro-title">Alterar Plano Premium</span>
            <span class="alterar-subtitle">Altere o seu plano</span>
            <div class="sinc-equip">
              <span class="sinc-title">Equipamentos sincronizados</span>
              <span class="sinc-description">Escolha quais os equipamentos que deseja ter sincronizados no Dialog.</span>
            </div>
            <div class="equip-edit">
              <input
                class="input-equip"
                type="number"
                placeholder="1"
              >
              <b-button
                variant="none"
                class="edit-btn-equip"
              >
                Edit
              </b-button>
            </div>
            <b-row class="morada">
              <b-col cols="12" xl="6">
                <div class="fat-morada">
                  <span class="fat-title">Equipamentos sincronizados</span>
                  <span class="fat-description">
                    Z.I. Norte Rua do Portinho<br/>
                    3750-175<br/>
                    Águeda<br/>
                    Portugal
                  </span>
                </div>
              </b-col>
              <b-col class="equip-edit" cols="12" xl="6">
                <b-button
                  variant="none"
                  class="edit-btn-equip"
                >
                  Edit
                </b-button>
                <b-button
                  variant="none"
                  class="del-btn-alt"
                >
                  Delete
                </b-button>
              </b-col>
            </b-row>
            <b-row class="card-details">
              <b-col cols="12" xl="6">
                <div class="card-detail">
                  <img src="https://fakeimg.pl/62x19/" alt="">
                  <span class="card-name">Mildred Wagner</span>
                  <span class="card-number">**** **** 5678</span>
                </div>
              </b-col>
              <b-col cols="12" xl="6" class="card-edit">
                <div>
                  <b-button
                    variant="none"
                    class="edit-btn-equip"
                  >
                    Edit
                  </b-button>
                  <b-button
                    variant="none"
                    class="del-btn-alt"
                  >
                    Delete
                  </b-button>
                </div>
                <div class="mt-1">
                  <span class="card-expires">Card expires at 02/24</span>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col offset-xl="1" xl="3" class="plans-payment">
            <b-card class="plans-content">
              <b-card-body class="year-content">
                <div class="year-title">
                  <span class="pay-title">
                    Pago Anualmente
                  </span>
                </div>
                <b-card class="content-section">
                  <b-card-body>
                    <div class="plans-value">
                      <span class="month-value">
                        <span class="value-text">10</span>
                        <span class="value-simbol">€</span>/mês
                      </span>
                      <span class="total-value">Ao preço de 120€/ano</span>
                      <hr>
                      <span class="premium-text">Dialog Premium</span>
                      <span class="premium-value">10€/mês | 1 equipamento</span>
                      <b-button
                        variant="none"
                        class="plan-btn"
                      >
                        Alterar
                      </b-button>
                    </div>
                  </b-card-body>
                </b-card>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCardText, BButton, BRow, BCol, BFormGroup, 
  BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BProgress, BProgressBar, BTab, BTabs,
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BListGroup,
    BCardText,
    BListGroupItem,
    BProgress,
    BProgressBar,
    BTab, 
    BTabs,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    const permissionsData = [
      {
        type: 'New for you',
        email: true,
        browser: false,
        app: false,
      },
      {
        type: 'Account activity',
        email: false,
        browser: true,
        app: false,
      },
      {
        type: 'A new browser used to sign in',
        email: true,
        browser: false,
        app: true,
      },
      {
        type: 'A new device is linked',
        email: false,
        browser: false,
        app: false,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      permissionsData,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>